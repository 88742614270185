<template>
    <b-container fluid>

        <b-row class="mb-2">
            <b-col>
                <b-form-input type="text" v-model="filter.barcode" placeholder="ШК"/>
            </b-col>
            <b-col>
                <b-form-input type="text" v-model="filter.placeId" placeholder="DLV PlaceId"/>
            </b-col>
            <b-col>
                <b-form-select v-model="filter.shipmentType" :options="shipmentTypeOptions"/>
            </b-col>
            <b-col>
                <b-form-checkbox v-model="filter.accepted">Приняты</b-form-checkbox>
            </b-col>
        </b-row>

        <b-table stacked="md" class="text-center" :items="entities" :fields="tableFields">
            <template v-slot:cell(actions)="data">
                                <b-link class="ml-4" :to="{ name: 'place-editor', params: {id: data.item.id}}">
                                    Просмотр
                                </b-link>
            </template>
        </b-table>
        <pagination :total-elements="pagination.totalElements" @change="paginationChange"/>
    </b-container>
</template>

<script>

    import Pagination from "../Pagination.vue";

    export default {
        name: "ShipmentBrowser",
        components: {Pagination},
        created() {
            this.getEntities();
        },
        watch: {
            filter: {
                handler() {
                    this.getEntities();
                },
                deep: true
            }
        },
        data() {
            return {
                entities: [],
                pagination: {
                    currentPage: 1,
                    totalElements: 0,
                    pageSize: 20,
                },
                filter: {
                    barcode: null,
                    placeId: null,
                    shipmentType: null,
                    accepted: false
                },
                tableFields: [
                    {
                        key: 'shipment.num',
                        label: 'Номер отправления'
                    }, {
                        key: 'shipment.dlvLogin',
                        label: 'Аккаунт'
                    }, {
                        key: 'barcode',
                        label: 'ШК',
                    }, {
                        key: 'dlvPlaceId',
                        label: 'Идентификатор места в DLV',
                    }, {
                        key: 'accepted',
                        label: 'Принято',
                    }, {
                        key: 'dimension',
                        label: 'Размеры',
                        formatter: value => "(" + value.width + "x" + value.height + "x" + value.depth + ") " + value.weight + "г."
                    }, {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
                statusOptions: [
                    {
                        value: null,
                        text: 'Статус',
                    }, {
                        value: 'SENDER',
                        text: 'У отправителя'
                    }, {
                        value: 'COURIER',
                        text: 'У курьера'
                    }, {
                        value: 'SORT',
                        text: 'На складе'
                    }, {
                        value: 'COURIER2',
                        text: 'Доставляется получателю'
                    }, {
                        value: 'PROBLEM',
                        text: 'Проблема'
                    }, {
                        value: 'DELIVERED',
                        text: 'Доставлен'
                    }, {
                        value: 'RETURNED',
                        text: 'Возвращён'
                    }, {
                        value: 'AT_TAKEOUT_POINT',
                        text: 'В пункте самовывоза'
                    }, {
                        value: 'RETURNING',
                        text: 'Возвращается отправителю'
                    }, {
                        value: 'LOST',
                        text: 'Потерян'
                    }, {
                        value: 'PARTIALLY_DELIVERED',
                        text: 'Частично доставлен'
                    },
                ],
                courierCodeOptions: [
                    {
                        value: null,
                        text: 'Служба доставки'
                    },
                    'SPSR',
                    'CityExpress',
                    'PickPoint',
                    'IML',
                    'Hermes',
                    'CDEK',
                    'Grastin',
                    'Boxberry',
                    'DPD',
                    'GD',
                    'RussianPost',
                    'RussianPostApi',
                    'MaxiPost',
                    'PickUp',
                    'PonyExpress',
                    'YandexDelivery',
                    'CSE',
                    'DOSTAVISTA',
                    'PEK',
                    'TEMPOLINE_RUSSIAN_POST',
                    'STRIZH',
                    'Logsis',
                    'A1'
                ],
                shipmentTypeOptions: [
                    {
                        value: null,
                        text: 'Тип отправления'
                    },
                    {
                        value: 'LITE_SHIPMENT_1',
                        text: 'Сбор'
                    },
                    {
                        value: 'LITE_SHIPMENT_2',
                        text: 'Доставка'
                    },
                    // {
                    //     value: 'RETURN',
                    //     text: 'Возврат'
                    // },
                ],
            }
        },
        methods: {
            getEntities() {
                this.$http.get(this.$root.apiUrl + '/api/places',
                    {
                        params: {
                            page: this.pagination.currentPage - 1,
                            size: this.pagination.pageSize,
                            filter: JSON.stringify([
                                {
                                    key: "barcode",
                                    value: this.filter.barcode,
                                    operation: "="
                                }, {
                                    key: "placeId",
                                    value: this.filter.placeId,
                                    operation: "="
                                }, {
                                    key: "shipment.shipmentType",
                                    value: this.filter.shipmentType,
                                    operation: "="
                                }, {
                                    key: "accepted",
                                    value: this.filter.accepted,
                                    operation: "="
                                },
                            ])
                        },
                        headers: this.$root.getHeaders()
                    }
                ).then(response => {
                    if (response.body.success) {
                        this.entities = response.body.list;
                        this.pagination.totalElements = response.body.pagination.totalElements;
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
            },
            remove(id) {
                this.$http.delete(this.$root.apiUrl + '/api/place/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success)
                            this.getEntities();
                        else
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            paginationChange(pagination) {
                this.pagination.currentPage = pagination.page;
                this.pagination.pageSize = pagination.size;
                this.getEntities();
            },
            translateStatus(status) {
                let tr = this.statusOptions.find(value => value.value === status);
                if (tr)
                    return tr.text;
                else
                    return status;
            },
        }
    }
</script>

<style scoped>

</style>